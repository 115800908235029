:root {
  --background-color-header: #181f1b;
  --color-warning: #cb7901;
  --color-copper: #e5756a;
  --font-gilroy: "Gilroy-Regular", Helvetica;
  --font-inter: "Inter-Regular", Helvetica;
  --color-text-highlight: #ff4040;
  --color-button-background-not-select: #d3d3d3;
  --color-button-background-not-select-contrast: #868686;
  --color-disabled: #ddf5e8;

  background-color: var(--background);
  color: var(--background-contrast);
}

.header .title {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: 50px;
  margin-right: 15px;

  @media (width <= 950px) {
    display: none;
  }
}

.header .overlap-group {
  background-image: url(./assets/circle.svg);
  background-size: 100% 100%;
  height: 54px;
  width: 54px;
  flex: 1;
  display: flex;
  align-items: center;
}

.header .group {
  height: 24px;
  width: 27px;
  flex: 1;
}

.header .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 32px;
  left: 402px;
  position: absolute;
  top: 257px;
}

.header .frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.header .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.header .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.header .frame-3 {
  align-items: center;
  background-color: var(--color-card-background);
  border: 0.5px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.25)) 1;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 16px 32px;
  position: relative;
}

.header .camada {
  height: 80px;
  position: relative;
  width: 108.86px;
}

.header .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.header .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.header .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.header .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.header .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.header .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.header .group-2 {
  background-color: var(--color-card-background-contrast);
  border-radius: 8px;
  height: 16px;
  position: relative;
  width: 16px;
}

.header .vector {
  height: 7px;
  left: 3px;
  position: absolute;
  top: 5px;
  width: 6px;
}

.header .img {
  height: 3px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 3px;
}

.header .frame-9 {
  align-items: center;
  background: var(--color-button-background);
  color: var(--color-button-background-contrast);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 33px;
  padding: 12px 0px;
  position: relative;
  width: 238px;
}

.header .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.header .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.header .frame-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.header .frame-13 {
  align-items: center;
  background-color: var(--color-old-button-background);
  border: 0.5px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.25)) 1;
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 16px 32px;
  position: relative;
}

.header .p {
  color: var(--color-card-background-contrast);
  font-family: var(--font-gilroy);
  font-size: 32px;
  left: 369px;
  position: absolute;
  top: 154px;
  white-space: nowrap;
}

.header .mask-group {
  height: 15px;
  margin-bottom: -2px;
  margin-top: -2px;
  position: relative;
  width: 15.83px;
}

.header .ftm {
  height: 33px;
  position: relative;
  width: 33px;

  @media (width <= 750px) {
    display: none;
  }
}

.header .mask-arrow {
  height: 20px;
  position: relative;
  width: 20px;
  color: var(--color-card-background-contrast);
}

.page-container {
  justify-content: center;
  display: flex;
  flex: 1;
  padding: 40px 20px;
}

.page-container .title {
  font-family: var(--font-gilroy);
  color: var(--color-card-background-contrast);
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  @media (width <= 1150px) {
    font-size: 22px;
  }
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-danger {
  color: var(--color-copper) !important;
}

.claim-btn {
  margin-top: 30px;
  width: 200px;
  height: 30px;
  border-radius: 6px;
  background: var(--color-button-background);
  border: 0;
  font-size: 12px;
  cursor: pointer;
}

.claim-btn:hover {
  opacity: 0.8;
}

/* Global Summary Table */

.white-card,
.white-tech-card {
  display: flex;
  padding: 16px 32px 10px 32px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 2px var(--color-button-background);
  flex: 1;
  flex-direction: column;
  font-size: 12px;
}

.white-card {
  background: var(--color-card-background);
  color: var(--color-card-background-contrast);
}

.white-tech-card {
  background: var(--color-card-background);
  color: var(--color-card-background-contrast);
}

.top-market-text {
  margin-top: 20px;
  margin-bottom: 15px;
}

.top-market {
  display: flex;
  align-items: center;
  gap: 10px;
}

.top-market:last-child {
  margin-bottom: 0px;
}

.top-market img {
  height: 24px;
}

.top-market .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
}

.top-market .top-market-inner-text {
  font-size: 12px;
}

.top-market > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Rewards */

.table-row .table-cell.apy-cell {
  display: flex;

  @media (width <= 560px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
  }
}

.table-row .table-cell .apy-cell-value img {
  @media (width > 560px) {
    display: none;
  }
}

.reward {
  display: flex;
  align-items: center;
}

.reward img {
  width: 12px;
  height: 12px;
}

.reward > div {
  border-radius: 4px;
  color: var(--color-button-background);
  align-items: center;
  gap: 4px;
  display: flex;
}

.reward.redreward {
  color: var(--color-copper);
}

.reward.redreward > div {
  color: var(--color-copper);
}

/* Markets Table */

.markets-table {
  background: var(--color-card-background);
  color: var(--color-card-background-contrast);
  font-size: 14px;
  box-shadow: 0px 0px 10px 2px var(--color-button-background);
  border-radius: 12px;

  @media (width <= 560px) {
    font-size: 11px;
    min-width: 430px;
  }
}

.markets-table .table-row:hover {
  background-color: var(--color-card-background-hover);
}

.white-card .table-row.no-items {
  padding: 10px 32px 0px 32px;
  font-size: 12px;
}

.white-card .no-borrow,
.markets-table .no-borrow {
  justify-content: center !important;
  display: flex;
  height: 16px;
  align-items: center;
}

.markets-table > div > div {
  display: flex;
}

.markets-table > div > div:not(:first-child) {
  justify-content: flex-end;
  text-align: end;
}

.markets-table .table-header {
  padding: 16px 32px 10px 32px;
}

.markets-table .table-row {
  padding: 10px 32px 10px 32px;
}

.markets-table .table-row:last-child {
  border-radius: 0 0 12px 12px;
}

.table-cell.table-cell-increase {
  flex: 1;
}

.table-cell.balance-cell {
  justify-content: flex-end;
  display: flex;
}

.table-header,
.table-row {
  display: flex;
  gap: 10px;
  border-bottom: 0px;

  @media (width <= 1150px) {
    padding: 12px 12px;
  }

  @media (width <= 560px) {
    gap: 10px;
  }
}

.table-row {
  padding-bottom: 6px;
  height: 28px;
  font-size: 12px;
}

.table-row-buttons {
  padding: 0px 32px 10px 32px;
}

.white-card .table-row:last-child {
  padding-bottom: 0px;
}

.top-market-token {
  flex: 1;
}

.table-row.no-items {
  justify-content: center;
  align-items: center;
}

.table-header {
  border-radius: 12px 12px 0 0;
  height: 20px;
  font-weight: 600;
  background: var(--color-card-background);
  opacity: 80%;
  padding-bottom: 10px;
  font-size: 14px;
  align-items: center;
}

.markets-table .table-cell {
  color: var(--color-card-background-contrast) !important;
  align-items: center;
  user-select: none;
  display: flex;
}

.table-row .table-cell {
  gap: 10px;
  display: flex;
  align-items: center;
}

.table-cell .asset {
  display: flex;
  flex-direction: column;
}

.table-cell .asset.asset-disabled {
  flex-direction: row;
  gap: 5px;
}

.table-cell .asset .price {
  color: var(--color-old-button-background);
  font-size: 10px;
  justify-content: left;
  font-weight: 600;
}

.table-cell .asset .amount-name {
  color: var(--color-old-button-background);
  font-size: 10px;
  justify-content: end;
}

.table-cell .asset > div {
  justify-content: right;
  align-items: center;
  display: flex;
  gap: 4px;
}

.table-cell .asset .name:not(.nohover):hover {
  text-decoration: underline;
}

.table-cell .asset .amount img {
  width: 16px;
  height: 16px;
  align-items: center;
}

.table-cell .logo img {
  width: 24px;
  height: 24px;
}

.table-cell.percentage-cell {
  min-width: 50px;
}

.table-cell .reward-cell-inline {
  display: none;

  @media (width <= 560px) {
    display: flex;
  }
}

.max-btn {
  transition: all 0.5s ease;
  background: var(--color-button-background);
  color: var(--color-button-background-contrast);
  border: 0;
  padding: 3px 15px;
  border-radius: 5px;
  font-size: 10px !important;
  cursor: pointer;
}

.max-btn:hover {
  opacity: 80%;
}

.regular-btn {
  border: 0;
  padding: 10px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  background: var(--color-button-background-highlight);
  color: var(--color-button-background-highlight-contrast);
  justify-content: center;
  display: flex;
  height: 35px;
  align-items: center;
}

.hashloader-wrapper {
  display: flex;
  justify-content: center;
}

.redback {
  background: var(--color-copper) !important;
}

.redcolor {
  color: var(--color-copper) !important;
}

.regular-btn.unselected {
  background: transparent !important;
}

.regular-btn.disabled {
  background: var(--color-disabled) !important;
}

.app > div:first-child {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

button:disabled {
  cursor: not-allowed !important;
  background-color: var(--color-disabled) !important;
  color: var(--color-button-background-not-select-contrast) !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Tooltip */

.react-tooltip {
  background: var(--background) !important;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.no-visible {
  visibility: hidden;
}

.min-width-90 {
  min-width: 90px;

  @media (width <= 560px) {
    min-width: inherit;
  }
}

.min-width-110 {
  min-width: 110px;
}
