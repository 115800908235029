.staking.page-container {
  flex: 0;
}

.staking .staking-selector {
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
}

.staking .mon-stake-btn,
.staking .fmon-stake-btn {
  width: 150px;
  padding: 8px 0px;
  border-radius: 8px;
  border: 0;
  font-size: 12px;
}

.staking .mon-stake-btn {
  background-color: var(--color-card-background-contrast);
  color: var(--color-card-background);
}

.staking .fmon-stake-btn {
  background-color: var(--color-card-background);
  color: var(--color-card-background-contrast);
}

.staking .staking-wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 1300px;

  @media (width <= 1150px) {
    flex-direction: column;
  }
}

.staking .staking-unstaked-tooltip {
  display: flex;
  align-items: center;
  gap: 5px;
}

.staking .staking-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;
}

.staking .staking-stake-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.staking .staking-stake-wrapper > div:last-child {
  flex: 1;
}

.staking .staking-stake {
  gap: 20px;
  flex: 0;
}

.staking .staking-stake * {
  font-size: 12px;
}

.staking .staking-balance,
.staking .staking-ratio {
  flex-direction: row;
  flex: 0;
  background-color: var(--color-button-background-highlight);
}

.staking .staking-balance-text {
  margin-left: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--color-button-background-highlight-contrast);
  font-weight: 600;
}

.staking .staking-balances {
  display: flex;
}

.staking .staking-balances-info {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.staking .staking-balance .staking-staked-summary > div {
  display: flex;
}

.staking .staking-balance-summary,
.staking .staking-staked-summary {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.staking .staking-ratio-summary-apr,
.staking .staking-staked-summary-apr {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.staking .staking-staked-summary > div {
  display: flex;
}

.staking .staking-ratio-summary-apr > div,
.staking .staking-staked-summary-apr > div {
  display: flex;
  justify-content: center;
}

.staking .staking-summary-text {
  margin-bottom: 8px;
  font-size: 14px;
}

.staking .staking-ratio-summary-apr .staking-summary-text,
.staking .staking-staked-summary-apr .staking-summary-text {
  font-weight: 600;
  font-family: Gilroy-Bold;
  text-decoration: underline;
}

.staking .staking-text {
  font-size: 14px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: var(--color-old-button-background);
}

.staking .staking-text.equality > div {
  flex: 1;
  justify-content: center;
}

.staking .staking-text > div {
  gap: 4px;
  display: flex;
  align-items: center;
  display: flex;
}

@media (width <= 1150px) {
  .staking .staking-ratio .staking-text {
    flex-direction: column;
  }

  .staking .staking-text > div {
    justify-content: center;
  }
}

@media (width <= 1565px) {
  .staking .staking-ratio .staking-text {
    align-items: center;
  }

  .staking .staking-ratio .staking-text > div {
    align-items: center;
  }
}

.staking .staking-tooltip {
  display: flex;
  align-items: center;
  gap: 5px;
}

.staking .staking-token {
  color: var(--color-text-highlight);
  font-size: 18px;
  font-weight: 600;
}

.staking .staking-subtitle {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.staking .staking-text-usd {
  font-size: 12px;
  color: var(--color-old-button-background);
}

.staking .staking-token-image {
  width: 24px;
  height: 24px;
}

.staking .staking-individual-tokens-wrapper {
  display: flex;
  flex-direction: column;
}

.staking .staking-individual-tokens-wrapper .staking-text-usd {
  justify-content: flex-end;
  display: flex;
}

.staking .staking-individual-tokens {
  display: flex;
}

.staking .staking-individual-tokens > div:first-child {
  display: flex;
  gap: 5px;
  align-items: center;
}

.staking .staking-individual-tokens > div:last-child {
  flex: 1;
}

.staking .staking-sapr-value > div {
  border-radius: 4px;
  color: var(--color-button-background);
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  display: flex;
  font-size: 12px;
}

.staking .staking-sapr-value img {
  width: 18px;
  height: 18px;
}

.staking .staking-gray-text {
  font-size: 12px;
  color: var(--color-old-button-background);
}

.staking .staking-claims {
  gap: 8px;
  font-size: 12px;
  justify-content: center;
  flex: 0;
}

.staking .staking-claims .claim-rewards {
  width: 100%;
  min-width: 120px;
  align-self: flex-end;
}

.staking .staking-claims > div > div:last-child {
  justify-content: flex-end;
  display: flex;
}

.staking .staking-claims > div > div:first-child {
  flex: 6;
}

.staking .staking-action-title {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.staking .staking-action-title > div:first-child {
  flex: 1;
}

.staking .staking-action-title-max {
  display: flex;
  align-items: center;
  gap: 10px;
}

.staking .staking-action-title-text {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--color-text-highlight);

  @media (width <= 1150px) {
    display: flex;
    gap: 4px;
  }
}

.staking .staking-token-text {
  font-size: 14px;
  font-weight: 500 !important;
  cursor: pointer;
  color: var(--background-contrast);
}

.staking .staking-action-input-wrapper {
  border: 1px solid var(--color-old-button-background);
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
}

.staking .staking-action-commit {
  display: flex;
  gap: 20px;
}

.staking .staking-action-commit > button {
  flex: 1;
}

.staking .staking-commit-amount {
  display: flex;
  flex: 1;
}

.staking .staking-commit-amount > input {
  border: 0;
  background-color: transparent;
  width: 100%;
  color: var(--color-card-background-contrast);
}

.staking .staking-commit-amount > input:focus {
  outline: none;
}

.staking .staking-commit-icon {
  align-items: center;
  display: flex;
}

.staking .staking-action-buttons {
  display: flex;
  gap: 20px;
}

.staking .staking-action-buttons button {
  flex: 1;
}

.staking .staking-action-buttons button.selected {
  color: var(--color-button-background-contrast);
}

.staking .staking-ghost {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.staking .staking-rewards {
  flex: 0;
}

.staking .no-assets {
  padding-bottom: 6px;
  font-size: 18px;
  font-style: italic;
  background: var(--color-card-background);
  color: var(--color-card-background-contrast);
  border-radius: 12px;
}

.staking .total-borrow-header > div:nth-child(2),
.staking .top-market > div:nth-child(2) {
  justify-content: right;
  display: flex;
}

.staking .staking-buttons {
  display: flex;
  flex-direction: row;
  padding-bottom: 6px;
}

.staking .staking-buttons > button,
.staking .staking-unlocked-buttons > button {
  flex: 1;
}

.staking .total-borrow-header {
  font-weight: 600;
  opacity: 80%;
  font-size: 14px;
  margin-bottom: 10px;
}

.staking .regular-btn.unselected {
  border: 1px dashed var(--color-button-background-highlight);
  color: var(--color-card-background-contrast) !important;
}

.staking .regular-btn {
  color: var(--color-button-background-contrast);
}

.staking .tooltip-text {
  color: rgba(246, 255, 250, 1);
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
}

.staking .tooltip-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.staking .tooltip-container > div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.staking .tooltip-container .tooltip-reward-desc-fees {
  width: 32px;
  justify-content: right;
  display: flex;
}

.staking .tooltip-container .tooltip-reward-desc {
  width: 32px;
  justify-content: center;
  display: flex;
}

.staking .tooltip-container .tooltip-text {
  color: var(--color-button-background) !important;
  font-weight: 600;
  padding-left: 10px;
}

.staking #reward-tooltip {
  font-family: var(--font-gilroy);
  font-size: 32px;
}

.staking .tooltip-container div {
  color: white;
}

.staking .borrow-slider {
  width: 100%;
  height: 20px;
}

.staking .borrow-slider-thumb {
  background: var(--color-button-background);
  color: var(--color-button-background-contrast);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 10px;
  cursor: pointer;
}

.staking .min-width-95 {
  min-width: 95px;

  @media (width <= 560px) {
    min-width: inherit;
  }
}

.staking .min-width-70 {
  min-width: 70px;

  @media (width <= 560px) {
    min-width: inherit;
  }
}

.staking .borrow-slider-thumb.disabled {
  background: var(--color-disabled);
  cursor: not-allowed;
}

.staking .borrow-slider-mark {
  background: var(--color-card-background);
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 4px;
  border: 1px solid var(--color-button-background);
}

.staking .borrow-slider-mark.redborder {
  border: 1px solid var(--color-button-background) !important;
}

.staking .borrow-slider-track {
  background: var(--color-old-button-background);
  top: 8px;
  height: 3px;
  margin-left: 8px;
  margin-right: 12px;
}

.staking .staking-tvl {
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 36px;
  flex: 0 !important;
  padding-bottom: 16px;

  @media (width <= 1150px) {
    flex-direction: column;
  }
}

.staking .staking-tvl-text {
  font-family: var(--font-gilroy-bold);
  color: var(--color-card-background-contrast);
  flex: 1;
  align-items: center;
  display: flex;
}

.staking .staking-tvl-amount {
  font-family: var(--font-gilroy-bold);
  color: var(--color-text-highlight);
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.staking .borrow-slider-text {
  display: flex;
}

.staking .borrow-slider-text > div {
  flex: 1;
  display: flex;
}

.staking .borrow-slider-text-0 {
  justify-content: flex-start;
}

.staking .borrow-slider-text-25 div {
  padding-left: 18px;
}

.staking .borrow-slider-text-50 {
  justify-content: center;
}

.staking .borrow-slider-text-75 div {
  padding-left: 54px;
}

.staking .borrow-slider-text-100 {
  justify-content: flex-end;
}

.staking .top-market > div:first-child > .top-market-inner-text {
  color: var(--color-text-highlight);
  font-weight: 600;
}
