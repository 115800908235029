.footer {
  display: flex;
  position: fixed;
  bottom: 0;
}

.footer .footer-icons,
.footer .footer-icons-social {
  display: flex;
  padding: 5px;
  color: #f3f3f3;
  background-color: #1a1d26;
  border: 1px solid #33394b;
  border-radius: 16px;
}

.footer .footer-icons {
  cursor: pointer;
  transition: all 1s ease;
  margin-left: 25px;
  margin-bottom: 25px;
  margin-top: 25px;
}

.footer .footer-icons.complementary {
  border-radius: 16px 0px 0px 16px;
  border-right: 0;
}

.footer .footer-icons-social {
  transition: all 0.5s ease;
  width: 150px;
  margin-bottom: 25px;
  margin-top: 25px;
  gap: 5px;
  border-left: 0;
  border-radius: 0 16px 16px 0;
}

.footer .footer-icons-social.hidden {
  width: 0;
  padding: 0;
  border: 0;
}

.footer .footer-icons-social > a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .footer-icons-social svg {
  transition: all 0.5s ease;
}

.footer .footer-icons-social.hidden svg {
  width: 0;
}

.footer .footer-icons-filler {
  flex: 1;
}
