body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Gigalypse";
  src:
    local("Gigalypse"),
    url("./assets/fonts/Gigalypse.otf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src:
    local("Gilroy-Regular"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
}


@font-face {
  font-family: "Gilroy-Bold";
  src:
    local("Gilroy-Bold"),
    url("./assets/fonts/Gilroy-Heavy.ttf") format("truetype");
}

* {
  font-family: var(--font-inter);
}
