.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.modal-container .modal-container-background {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--color-old-button-background);
  opacity: 20%;
}

.modal-container .modal {
  z-index: 1;
  flex: 0;
}

.modal-container .modal_header {
  position: relative;
}

.modal-container .modal_header-title {
  text-align: center;
}

.modal-container .modal_header_content {
  padding: 2rem 0;
}

.modal-container .modal_header_footer {
  padding: 2rem 0;
  padding-bottom: 0;
}

.modal-container .modal_header_footer button {
  float: right;
  padding: 0.5rem;

  border-radius: 8px;
}

.modal-container.hide {
  visibility: hidden;
  opacity: 0;
}

.modal-container .modal_footer {
  display: flex;
  gap: 15px;
}

.modal-container .regular-btn {
  flex: 1;
}

.modal-container input {
  border: 0;
  background-color: transparent;
  width: 100%;
  color: var(--color-card-background-contrast);
  font-size: 12px;
}

.modal-container input:focus {
  outline: none;
}
