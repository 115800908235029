.disclaimer-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.disclaimer-modal * {
  font-size: 14px;
  transition: all 0.5s ease;
  color: var(--color-card-background-contrast);
}

.disclaimer-modal .disclaimer-modal-content {
  z-index: 11;
  width: 460px;
  position: fixed;
  gap: 10px;

  @media (width <= 560px) {
    width: 80vw;
  }
}

.disclaimer-modal .disclaimer-header {
  justify-content: center;
  display: flex;
  margin-bottom: 25px;
  font-family: var(--font-gilroy);
  font-size: 32px;
}

.disclaimer-modal .disclaimer-modal-background {
  background: var(--color-old-button-background);
  opacity: 20%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.disclaimer-modal .regular-btn {
  width: 100%;
  color: var(--color-button-background-contrast);
}

.disclaimer-modal .disclaimer-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.disclaimer-modal .disclaimer-buttons {
  margin-top: 32px;
}

.disclaimer-modal .disclaimer-checkbox {
  font-size: 12px;
  display: flex;
  align-items: center;
}
