.crematorium {
  .crematorium-content {
    flex-direction: column;
    display: flex;
    gap: 10px;
    flex: 1;
  }

  .crematorium-vaults {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .crematorium-vault-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .crematorium-vault-header {
    display: flex;
    gap: 10px;
    padding-bottom: 15px;
  }

  .crematorium-vault-tokens-name {
    flex: 1;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
  }

  .crematorium-vault-tokens-name-text {
    font-weight: 600;
  }

  .crematorium-vault-tokens-name-desc,
  .crematorium-vault-input-text,
  .crematorium-vault-claim-text {
    font-size: 12px;
  }

  .crematorium-vault-input-text {
    align-items: center;
    display: flex;
  }

  .crematorium-vault-input-text > span {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .crematorium-vault-input-text > span:first-child {
    flex: 1;
  }

  .crematorium-vault-input-text > span:last-child {
    cursor: pointer;
  }

  .crematorium-vault-input-text span {
    float: right;
  }

  .crematorium-vault-input-field {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .crematorium-vault-input-field input {
    flex: 1;
  }

  .crematorium-vault-input-field button {
    width: 80px;
  }

  .crematorium-vault-buttons-withdraw-input {
    width: 100%;
    background-color: var(--background);
    border: 0;
    height: 30px;
    padding: 0 10px;
    color: var(--color-card-background-contrast);
  }

  .crematorium-vault {
    background-color: var(--color-card-background);
    border: 2px solid #ff4040;
    border-radius: 8px;
    padding: 30px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    width: 280px;
  }

  .crematorium-vault-input {
    padding-top: 15px;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .crematorium-vault-tokens-img {
    img {
      width: 32px;

      &:last-child {
        margin-left: -10px;
      }
    }
  }

  .crematorium-vault-claim-img {
    img {
      width: 32px;
    }
  }

  .crematorium-vault-claim-value {
    align-items: center;
    display: flex;
    font-size: 14px;
    padding-right: 10px;
  }

  .crematorium-vault-tvl,
  .crematorium-vault-tokens-total,
  .crematorium-vault-apy,
  .crematorium-vault-buttons,
  .crematorium-vault-claim-field {
    display: flex;
    gap: 10px;
  }

  .crematorium-vault-claim {
    padding-top: 10px;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }

  .crematorium-vault-apy {
    justify-content: space-around;
    padding-top: 15px;
  }

  .crematorium-vault-buttons {
    padding-top: 10px;

    > div {
      flex: 1;
      display: flex;

      > button {
        flex: 1;
      }
    }
  }

  .crematorium-vault-claim-img {
    width: 32px;
  }

  .crematorium-vault-buttons-deposit {
    flex: 2;

    button {
      width: 100%;
    }
  }

  .crematorium-vault-tvl-value,
  .crematorium-vault-tokens-total-value,
  .crematorium-vault-apy-day-value,
  .crematorium-vault-apy-month-value,
  .crematorium-vault-apy-year-value {
    font-weight: 600;
  }

  .crematorium-vault-apy-day-text,
  .crematorium-vault-apy-month-text,
  .crematorium-vault-apy-year-text,
  .crematorium-vault-apy-day-value,
  .crematorium-vault-apy-month-value,
  .crematorium-vault-apy-year-value {
    text-align: center;
  }

  .selected-withdraw {
    background-color: var(--background);
    color: var(--color-card-background-contrast);
    font-weight: 600;
  }

  .selected-deposit {
    background-color: #fc7643;
    color: var(--color-card-background);
    font-weight: 600;
  }
}
