.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 2px 10px 0px var(--color-old-button-background);
}

.header .logo-pfp {
  width: 58px;
  height: 58px;
}

.connector {
  align-items: center;
  background: var(--color-button-background);
  color: var(--color-button-background-contrast);
  border-radius: 10px;
  display: inline-flex;
  gap: 8px;
  height: 33px;
  padding: 0px 10px;
  border: 0;
  cursor: pointer;
}

.connector svg {
  color: var(--color-button-background-contrast);
  width: 20px;
  height: 20px;

  @media (width <= 1150px) {
    width: 32px;
    height: 32px;
  }
}

.text-wallet {
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 11px;
  min-width: 125px;

  @media (width <= 1150px) {
    font-size: 10px;
  }
}

.connector-wrapper {
  justify-content: center;
  gap: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 50px;

  @media (width <= 950px) {
    justify-content: flex-end !important;
  }
}

.header .toggle-dark {
  background: var(--color-button-background);
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  color: var(--color-button-background-contrast);
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .logo-text {
  width: 125.31px;
  align-items: center;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  font-family: "Gigalypse";
  color: var(--color-card-background-contrast);
  padding-left: 4px;
  padding-top: 4px;
  letter-spacing: 1px;

  @media (width <= 950px) {
    width: inherit;
  }
}

.header .fghost-price {
  background: var(--color-button-background);
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  color: var(--color-button-background-contrast);
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  gap: 5px;
  font-size: 11px;
  font-weight: 600;
  width: 58px;

  @media (width <= 830px) {
    display: none;
  }
}

.header .fghost-price img {
  width: 16px;
  height: 16px;
  background: var(--background);
  border-radius: 20px;
}

.navbar {
  flex: 10;
  align-items: center;
  display: inline-flex;
  gap: 30px;
  padding-left: 20px;

  @media (width <= 950px) {
    flex: 1;
    margin-right: 0px;
    margin-left: 10px;
  }
}

.navbar nav {
  display: flex;

  @media (width <= 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.text-navbar-link:not(.selected) {
  @media (width <= 950px) {
    display: none;
  }
}

.text-navbar-link {
  text-align: center;
  align-items: center;
  display: flex;

  @media (width <= 950px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.text-navbar-link a {
  text-decoration: none;
  font-size: 14px;
  font-family: var(--font-inter);
  color: var(--color-old-button-background);
}

.text-navbar-link.selected a {
  text-decoration: none;
  font-size: 14px;
  font-family: var(--font-inter);
  color: var(--color-text-highlight);
  font-weight: 600;

  @media (width <= 950px) {
    color: var(--color-button-background-contrast);
    font-family: "Gigalypse";
    font-size: 32px;
  }
}

.text-navbar-link.selected svg {
  @media (width <= 950px) {
    font-size: 22px;
  }
}

.text-navbar-link-a {
  flex: 5;
}

.navbar .navbar-menu {
  background: var(--color-button-background);
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  color: var(--color-button-background-contrast);
  height: 33px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;

  @media (width > 950px) {
    display: none;
  }
}

.navbar .navbar-links {
  @media (width <= 950px) {
    display: none;
  }
}

.navbar-cover {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background-color: #225e3e;
  z-index: 1;
  opacity: 0.4;
}

.navbar-menu-mobile {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 0;
  background: #1a1d26;
  color: #f3f3f3;
  width: 100%;
  transition: height 0.5s ease;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.navbar-menu-mobile.show {
  height: 240px;
}

.navbar-menu-mobile a,
.navbar-menu-mobile button {
  transition:
    opacity 0.3s ease,
    height 0.5s ease,
    background-color 0.3s ease-in;
  opacity: 0;
  height: 0;
  font-family: var(--font-gilroy);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  gap: 10px;
  width: 100%;
  color: var(--color-card-background-contrast);
}

.navbar-menu-mobile.show a,
.navbar-menu-mobile.show button {
  height: 40px;
  opacity: 1;
}

.navbar-menu-mobile.show a.selected {
  color: var(--color-text-highlight);
}

.navbar-menu-mobile.show a:hover,
.navbar-menu-mobile.show button:hover {
  background-color: var(--color-button-background);
}
